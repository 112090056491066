<template>
  <div class="HelpView">
    <div class="o-container-gray">
      <md-card class="md-layout-item">

        <md-card-header>
          <SectionTitle :titleContent="messages.title"/>
        </md-card-header>

        <md-card-content>
          <div class="o-container-subsection">
            <md-list>
              <md-list-item v-for="link in links"
                :key="link.id"
                :href="link.page">{{ link.text }}</md-list-item>
            </md-list>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';

export default {
  name: 'HelpView',
  components: {
    SectionTitle,
  },
  data() {
    return {
      links: [
        {
          id: 0,
          text: this.$t('helpView.subtitle1'),
          page: '/help/faq'
        },
        {
          id: 1,
          text: this.$t('helpView.subtitle2'),
          page: '/help/payments'
        },
        {
          id: 2,
          text: this.$t('helpView.subtitle3'),
          page: '/help/auctions'
        },
        {
          id: 3,
          text: this.$t('helpView.subtitle4'),
          page: '/help/dns'
        },
      ],
      messages: {
        title: this.$t('helpView.title'),
      }
    };
  },
};
</script>
